
import {
    defineComponent, ref, watch
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertySubRouter, propertyBaseRouter } from '@/router';
import { form as userForm, FormData, officeForm } from '@/components/view/pm/set-resident';
import HttpRequest from '@/util/axios.config';
import { community } from '@/data';
import { residentApi } from '@/api';

export default defineComponent({
    components: {
        propertyBreadCrumb,
        userForm,
        officeForm
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        // 区别办公、社区详情
        const projectType = community.ProjectType;
        const breadHeaders = ref([
            {
                label: WordList.RDeviceResidents,
                path: `/${propertyBaseRouter}/${propertySubRouter.resident}`
            },
            {
                label: WordList.ProperAllTextEditInformation
            }
        ]);
        watch(community.ProjectType, () => {
            if (community.ProjectType.value === 'office') {
                breadHeaders.value[0].label = WordList.ProperAllTextPeople;
            }
        }, {
            immediate: true
        });

        const commit = ref(0);
        const checkForm = () => {
            commit.value += 1;
        };

        const submit = (data: {
            basicData: FormData;
        }) => {
            const api = projectType.value === 'office' ? 'editOfficeUserInfo' : 'editUserInfo';
            residentApi[api]({ ...data.basicData, ID: props.id }, () => {
                router.push(`/${propertyBaseRouter}/${propertySubRouter.residentInfo}?id=${props.id}&type=${projectType.value}`);
            });
        };

        return {
            breadHeaders,
            commit,
            checkForm,
            submit,
            projectType
        };
    }
});
